.App-logo {
  height: 16vmin;
  pointer-events: none;
}

.App-header {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.supportedchain-logo {
  max-width:50px;
}