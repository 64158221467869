@import "~bootstrap/scss/bootstrap.scss";
// @include color-mode(dark);

@font-face {
  font-family: "Jura";   
  src: local("Jura"), url("../media/fonts/Jura-VariableFont_wght.ttf") format("truetype");
}

:root{
  --bs-body-font-family: 'Jura';
  --font-weight: Regular;
  // --bs-body-color: #ffffff;  
  // --primary-color: black;
  // background-color: #000000;
}

a{
  color: #ffffff;
}

.accordion-button::after {background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2331910F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }
.accordion-button:not(.collapsed)::after {background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill='%2331910F'%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e")}

.accordion-button:not(.collapsed){
  color:#31910F;
  background-color:#000000;
  font-weight:600;
}
.image-max-width{
  max-width: 1000px;
  width:100%
}

.icon-green{
  color:#31910F;
}

.accordion-button:focus{
  border-color: #31910F;
  box-shadow: 0 0 0 0.25rem #17330d;
}